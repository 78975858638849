<template>
<div v-if="loading">
    <Loading :active.sync="loading" color="#042446"></Loading>
</div>
<div class="scope_novel" v-else>
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
        <Login />
    </b-modal>
    <b-modal v-model="showBuyModal" :hide-header="true" :hide-footer="true">
        <BuyBookModal :novel="detailForBookModal" />
    </b-modal>
    <b-modal v-model="showBuyChapterModal" :hide-header="true" :hide-footer="true">
        <BuyChapterModal :novel="detailForModal" />
    </b-modal>
    <b-modal v-model="showBuyChapterPackModal" :hide-header="true" :hide-footer="true">
        <BuyChapterPackModal :novel="detailForPackModal" />
    </b-modal>
    <b-modal v-model="showBuyChapterRangeModal" :hide-footer="true" @close="workerupdateUser();selectWorker()" @hide="workerupdateUser();selectWorker()">
        <BuyChapterRangeModal :novel="novelDetail" />
    </b-modal>
    <b-row id="componentScope1" class="mb-5 componentScope">
        <b-col sm="3" class="img">
            <b-img-lazy class="novelCover" fluid :src="novelDetail.novel_cover" @error.native="img_load_default_cover"></b-img-lazy>
        </b-col>
        <b-col>
            <p class="title">
                <a-tag color="red" v-if="is_not_update_a_month && novelDetail.novel_is_end === '0'">ไม่ได้อัปเดทมากกว่า 1 เดือน</a-tag>
                <a-tag color="green" v-if="novelDetail.novel_is_end === '1'">จบแล้ว</a-tag>
                &nbsp;{{novelDetail.novel_title}}
            </p>
            <a-avatar v-if="novelDetail.novel_author.member_profile_url == ''" size="large" icon="user" />
            <a-avatar v-else :src="novelDetail.novel_author.member_profile_url" size="large" />
            <label class="author_label" @click="gotoUser()">{{novelDetail.novel_author.member_displayname}}</label>
            <a-divider class="divider fontsize" />
            <p class="fontsize" v-html="novelDetail.novel_bio"></p>
            <hr v-if="novelDetail.novel_bio !== ''" />
            <NovelInfoCard :readCount="novelDetail.novel_read_count" :commentCount="commentCount" :chapterCount="novelDetail.novel_count" />
            <hr />
            <div v-if="isLogin">
                <div class="continueRead btn mr-3 fontsize" v-if="novelDetail.continue_chapter_id != false && novelDetail.continue_chapter_id.chapter_ep != -1" @click="continueReading(novelDetail.continue_chapter_id.chapter_id)">อ่านต่อ</div>
                <div class="addToShelf btn mr-3 fontsize" @click="addToShelf(novelDetail.novel_id)">
                    <span v-if="!isInShelf">+ เข้าคลังหนังสือ</span>
                    <b-spinner v-else-if="spin" small label="Small Spinner"></b-spinner>
                    <span v-else-if="isInShelf">- ลบออกจากคลังหนังสือ</span>
                </div>
                <div v-if="novelDetail.novel_is_end == 1 && isBuy == false && novelDetail.novel_is_hide != 1" @click="buyBook(novelDetail.novel_id)" class="buyNovel btn mr-3 fontsize mobile-w-auto w-full">
                    ซื้อยกเล่ม
                </div>
                <div v-else-if="isBuy" class="btn fontsize mobile-w-auto w-full" style="color:#042446">
                    <b>ซื้อยกเล่มแล้ว</b>
                </div>
            </div>
        </b-col>
        <div v-html="novelDetail.novel_desc" id="desc" class="desc fontsize"></div>
        <p @click="readMore()" id="textReadMore">อ่านเพิ่ม...</p>
    </b-row>

    <b-row id="componentScope2" class="mb-3 componentScope">
        <!-- table chapter -->
        <b-col class="chaptertable" v-if="$vssWidth>=540">
            <div class="tableScope  mt-2">
                <div class="headTable pb-3 mb-3">
                    <span class="fontsize mr-3">ตอนที่:</span>
                    <b-form-select v-model="selected" @change="selectWorker()" :options="options" size="sm" style="width: 15%;"></b-form-select>
                    <div v-if="novelDetail.novel_is_hide != 1">
                        <div v-if="!isPack" class="continueRead btn ml-1 fontsize" @click="routePack()">
                            <div v-if="isPacktoggleload">
                                <b-spinner label="Spinning"></b-spinner>
                            </div>
                            <div v-else>
                                ซื้อแบบแพ็คเกจ
                            </div>
                        </div>
                        <div v-else class="continueRead btn ml-1 fontsize" @click="routePage()">
                            <div v-if="isPacktoggleload">
                                <b-spinner label="Spinning"></b-spinner>
                            </div>
                            <div v-else>
                                ซื้อแบบรายตอน
                            </div>
                        </div>
                        <a-button v-if="isLogin" type="primary" class="ml-1" @click="openBuyRangemodal()">
                            ซื้อเป็นช่วง
                        </a-button>
                    </div>
                </div>
                <div v-if="isPack">
                    <b-row v-if="isLoadData">
                        <b-col>
                            <div class="text-center">
                                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-else v-for="(c,index) in chapter" :key="index" @click="checkBuyChapterPack(c)" class="pt-2 pb-2 mt-1 mb-1 chapter chapterpack">
                        <b-col cols="10">
                            <div>
                                <b-icon-bookshelf></b-icon-bookshelf> <span class="bold">ตอนที่</span> {{c.chapter_ep_first}} - {{c.chapter_ep_last}}
                            </div>
                        </b-col>
                        <b-col cols="2" v-if="c.chapter_price == 0 && novelDetail.novel_is_hide == 0" class="text-left">อ่านฟรี</b-col>
                        <b-col cols="2" v-else-if="c.chapter_price > 0 && (c.chapter_buy_id != undefined || c.chapter_buy_id != null)" class="text-left"><b class="bougth">ซื้อแล้ว</b></b-col>
                        <b-col cols="2" v-else-if="novelDetail.novel_is_hide == 1" class="text-left">ไม่มีขายแล้ว</b-col>
                        <b-col cols="2" v-else class="text-left"><img src="./../../assets/price.png" width="22px" class="mr-1 mb-1" />{{c.chapter_price}}</b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-row v-if="isLoadData">
                        <b-col>
                            <div class="text-center">
                                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-else v-for="(c,index) in chapter" :key="index" @click="checkBuyChapter(c)" class="pt-2 pb-2 mt-1 mb-1 chapter">
                        <b-col cols="9">
                            <div>
                                <span class="bold">ตอนที่</span> {{c.chapter_ep}}: {{c.chapter_name}}
                                <a-tag color="green" v-if="isLogin && (c.chapter_ep == novelDetail.continue_chapter_id.chapter_ep)" class="scroll_here">
                                    อ่านล่าสุด
                                </a-tag>
                            </div>
                            <div class="chapterLength">{{c.chapter_length}} อักษร</div>
                        </b-col>
                        <b-col cols="2" v-if="(typeof c.chapter_buy_count !== 'undefined')" class="verticalCenter">จำนวนผู้อ่าน: {{c.chapter_buy_count}}</b-col>
                        <b-col cols="2" v-else class="verticalCenter"></b-col>
                        <b-col cols="1" v-if="c.chapter_price == 0 && novelDetail.novel_is_hide == 0" class="text-left">อ่านฟรี</b-col>
                        <b-col cols="1" v-else-if="c.chapter_price > 0 && (c.chapter_buy_id != undefined || c.chapter_buy_id != null)" class="text-left"><b class="bougth">ซื้อแล้ว</b></b-col>
                        <b-col cols="1" v-else-if="novelDetail.novel_is_hide == 1" class="text-left">ไม่มีขายแล้ว</b-col>
                        <b-col cols="1" v-else class="text-left"><img src="./../../assets/price.png" width="22px" class="mr-1 mb-1" />{{c.chapter_price}}</b-col>
                    </b-row>
                </div>
            </div>
        </b-col>
        <b-col class="chaptertable" v-if="$vssWidth<=540">
            <div class="tableScope  mt-2">
                <div class="headTable pb-3 mb-3">
                    <span class="fontsize">ตอนที่:</span>
                    <b-form-select v-model="selected" @change="selectWorker()" :options="options" size="sm" style="width: 100%;"></b-form-select>
                    <div v-if="novelDetail.novel_is_hide != 1">
                        <div v-if="!isPack " class="continueRead btn ml-1 fontsize" @click="routePack()">
                            <div v-if="isPacktoggleload">
                                <b-spinner label="Spinning"></b-spinner>
                            </div>
                            <div v-else>
                                ซื้อแบบแพ็คเกจ
                            </div>
                        </div>
                        <div v-else class="continueRead btn ml-1 fontsize" @click="routePage()">ซื้อแบบรายตอน</div>
                        <a-button v-if="isLogin" type="primary" class="ml-1 w-full" @click="openBuyRangemodal()">
                            ซื้อเป็นช่วง
                        </a-button>
                    </div>
                </div>
                <div v-if="isPack">
                    <b-row v-if="isLoadData">
                        <b-col>
                            <div class="text-center">
                                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-else v-for="(c,index) in chapter" :key="index" @click="checkBuyChapterPack(c)" class="pt-2 pb-2 mt-1 mb-1 chapter chapterpack">
                        <b-col cols="8">
                            <div>
                                <b-icon-bookshelf></b-icon-bookshelf> <span class="bold">ตอนที่</span> {{c.chapter_ep_first}} - {{c.chapter_ep_last}}
                            </div>
                        </b-col>
                        <b-col v-if="c.chapter_price == 0" class="text-left">อ่านฟรี</b-col>
                        <b-col v-else-if="c.chapter_price > 0 && (c.chapter_buy_id != undefined || c.chapter_buy_id != null)" class="text-center">
                            <b-icon-check></b-icon-check>
                        </b-col>
                        <b-col v-else-if="novelDetail.novel_is_hide == 1" class="text-left">ไม่มีขายแล้ว</b-col>
                        <b-col cols="2" v-else class="text-left"><img src="./../../assets/price.png" width="22px" class="mr-1 mb-1" />{{c.chapter_price}}</b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-row v-if="isLoadData">
                        <b-col>
                            <div class="text-center">
                                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row v-else v-for="(c,index) in chapter" :key="index" @click="checkBuyChapter(c)" class="pt-2 pb-2 mt-1 mb-1 chapter">
                        <b-col cols="9">
                            <div>
                                <span class="bold">ตอนที่</span> {{c.chapter_ep}}: {{c.chapter_name}} 
                                <a-tag color="green" v-if="isLogin && (c.chapter_ep == novelDetail.continue_chapter_id.chapter_ep)" class="scroll_here">
                                    อ่านล่าสุด
                                </a-tag>
                            </div>
                            <div class="verticalCenter" v-if="(typeof c.chapter_buy_count !== 'undefined')">จำนวนผู้อ่าน: {{c.chapter_buy_count}}</div>
                            <div class="chapterLength">{{c.chapter_length}} อักษร</div>
                        </b-col>
                        <b-col v-if="c.chapter_price == 0" class="text-left">อ่านฟรี</b-col>
                        <b-col v-else-if="c.chapter_price > 0 && (c.chapter_buy_id != undefined || c.chapter_buy_id != null)" class="text-center">
                            <b-icon-check></b-icon-check>
                        </b-col>
                        <b-col v-else-if="novelDetail.novel_is_hide == 1" class="text-left">ไม่มีขายแล้ว</b-col>
                        <b-col v-else class="text-left"><img src="./../../assets/price.png" width="18px" class="mr-1 mb-1" />{{c.chapter_price}}</b-col>
                    </b-row>
                </div>
            </div>
        </b-col>
    </b-row>
    <Comment type="novel" :id="novelDetail.novel_id" />
</div>
</template>

<script>
const Login = resolve => {
    require.ensure(['./../pages/Login'], () => {
        resolve(require('./../pages/Login.vue'))
    })
}
const BuyChapterModal = resolve => {
    require.ensure(['./../elements/BuyChapterModal'], () => {
        resolve(require('./../elements/BuyChapterModal.vue'))
    })
}
const BuyChapterPackModal = resolve => {
    require.ensure(['./../elements/BuyChapterPackModal'], () => {
        resolve(require('./../elements/BuyChapterPackModal.vue'))
    })
}
const BuyChapterRangeModal = resolve => {
    require.ensure(['./../elements/BuyChapterRangeModal'], () => {
        resolve(require('./../elements/BuyChapterRangeModal.vue'))
    })
}
const BuyBookModal = resolve => {
    require.ensure(['./../elements/BuyBookModal'], () => {
        resolve(require('./../elements/BuyBookModal.vue'))
    })
}
const Comment = resolve => {
    require.ensure(['./../elements/comment/Comment'], () => {
        resolve(require('./../elements/comment/Comment.vue'))
    })
}
const NovelInfoCard  = resolve => {
    require.ensure(['./../elements/novel/NovelInfoCard'], () => {
        resolve(require('./../elements/novel/NovelInfoCard.vue'))
    })
}
import axios from 'axios'
import router from './../../router/index'
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    watch: {
        mode() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            loading: 'GET_LOADING',
            mode: 'GET_MODE'
        })
    },
    components: {
        Login,
        BuyChapterModal,
        BuyBookModal,
        BuyChapterPackModal,
        Comment,
        BuyChapterRangeModal,
        NovelInfoCard
    },
    async mounted() {
        this.SET_LOADING_STATUS(true)
        if (this.isLogin) {
            await this.getNovelDetail(true)
        } else {
            await this.getNovelDetail(false)
        }
        await this.getCommentCount()
        this.move_continue()
        this.getChapter()
        this.checkShelf(this.$route.params.novel_id)
    },
    data() {
        return {
            isLoadData: false,
            isLogin: localStorage.getItem('UserInfo') != null,
            novelDetail: {
                continue_chapter_id: false,
                novel_author:{
                    member_profile_url : ''
                }
            },
            chapter: [{
                chapter_name: "",
                chapter_ep: 0,
                chapter_length: 0,
                chapter_buy_count: 0,
                chapter_price: 0
            }],
            isPack: false,
            isPacktoggleload: false,
            isShowChapter: false,
            fields: [{
                    key: 'chapter_ep',
                    label: ''
                },
                {
                    key: 'chapter_name',
                    label: ''
                },
                {
                    key: 'chapter_length',
                    label: ''
                },
                {
                    key: 'chapter_price',
                    label: ''
                }
            ],
            selected: 0,
            options: [],
            isClickReadMore: false,
            isInShelf: false,
            spin: false,
            isBuy: false,
            showBuyModal: false,
            paymentInfo: {},
            goldEnough: false,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            showLoginModal: false,
            showBuyChapterModal: false,
            showBuyChapterPackModal: false,
            showBuyChapterRangeModal: false,
            detailForModal: "",
            detailForPackModal: "",
            detailForBookModal: "",
            initial: true,
            scroll: true,
            continue_page: 0,
            is_not_update_a_month: false,
            commentCount: 0
        }
    },
    async updated(){
        await this.scrollto()
    },
    metaInfo() {
        return { 
            title: this.novelDetail.novel_title+ " | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย",
            meta: [
                { name: 'description', content:  'NovelRealm นิยายแปลที่ถูกคัดสรรมาอย่างดี การันตีด้วยยอดผู้อ่านกว่าล้านครั้ง ทั้งนิยายแฟนตาซี นิยายกำลังภายใน นิยายรักโรแมนติก'},
                { property: 'og:title', content: this.novelDetail.novel_title+ " | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย"}
            ]
        }
    },
    methods: {
        ...mapActions(['SET_MEMBER_GOLD', 'SET_LOADING_STATUS']),
        scrollto(){
            const el = this.$el.getElementsByClassName('scroll_here')[0];
            if (this.isLogin && this.scroll) {
                if (el) {
                    el.scrollIntoView();
                    this.scroll = false
                }
            }
        },
        async workerupdateUser() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios.post('https://api2.novelrealm.com/module/profile&submodule=info', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            this.SET_USER_INFO({
                                auth_token: this.userInfo.auth_token,
                                auth_memberid: this.userInfo.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        img_load_default_cover(event){
            this.$parent.img_load_default_cover(event)
        },
        move_continue(){
            this.selected = this.continue_page
            this.scroll = true
            this.initial = false
        },
        readMore() {
            this.isClickReadMore = !this.isClickReadMore
            if (this.isClickReadMore) {
                document.getElementById('textReadMore').innerHTML = "ซ่อน..."
                document.getElementById('desc').classList.add("descActive")
            } else {
                document.getElementById('textReadMore').innerHTML = "อ่านเพิ่ม..."
                document.getElementById('desc').classList.remove("descActive")
            }
        },
        async checkBuyChapter(chapter) {
            if (chapter.chapter_price != 0 && this.isLogin != true) {
                this.showLoginModal = true
            } else {
                const formData = new FormData();
                if (this.isLogin) {
                    formData.append('auth_memberid', this.userInfo.auth_memberid);
                    formData.append('auth_reference', this.userInfo.auth_token);
                }
                formData.append('chapter_id', chapter.chapter_id);
                try {
                    await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buycheck', formData)
                        .then((res) => {
                            //console.log('buy check : ', res)
                            if (res.data.status == 200) {
                                router.push(window.location.pathname + "/chapter/" + chapter.chapter_id);
                            } else if (res.data.status == 404) {
                                this.detailForModal = {
                                    novel_id: this.novelDetail.novel_id,
                                    novel_cover: this.novelDetail.novel_cover,
                                    last_ep: chapter.chapter_ep,
                                    chapter_id: chapter.chapter_id,
                                    chapter_name: chapter.chapter_name,
                                    chapter_price: chapter.chapter_price,
                                    member_gold: res.data.member_gold,
                                    ishide: this.novelDetail.novel_is_hide
                                }
                                this.showBuyChapterModal = true
                            }
                        })
                } catch (err) {
                    //console.log('FAILURE!!' + err)
                }
            }

        },
        async checkBuyChapterPack(chapter) {
            if (chapter.chapter_price != 0 && this.isLogin != true) {
                this.showLoginModal = true
            } else {
                const formData = new FormData();
                if (this.isLogin) {
                    formData.append('auth_memberid', this.userInfo.auth_memberid);
                    formData.append('auth_reference', this.userInfo.auth_token);
                }
                formData.append('chapter_id_set', chapter.chapter_id_set);
                try {
                    await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buypackchapter', formData)
                        .then((res) => {
                            //console.log('buy check : ', res)
                            if (res.data.status == 200) {
                                router.push(window.location.pathname + "/chapter/" + res.data.chapter_id_first);
                            } else if (res.data.status == 404) {
                                this.detailForPackModal = {
                                    novel_id: this.novelDetail.novel_id,
                                    novel_cover: this.novelDetail.novel_cover,
                                    chapter_ep_first: chapter.chapter_ep_first,
                                    chapter_ep_last: chapter.chapter_ep_last,
                                    chapter_id_set: chapter.chapter_id_set,
                                    chapter_id_first: res.data.chapter_id_first,
                                    chapter_price: chapter.chapter_price,
                                    member_gold: res.data.member_gold,
                                    ishide: this.novelDetail.novel_is_hide
                                }
                                this.showBuyChapterPackModal = true
                            }
                        })
                } catch (err) {
                    //console.log('FAILURE!!' + err)
                }
            }

        },
        async getNovelDetail(isLogin) {
            if (isLogin) {
                const formData = new FormData();
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                try {
                    await axios.post('https://api2.novelrealm.com/module/novel&submodule=detail&novel_id=' + this.$route.params.novel_id, formData)
                        .then((res) => {
                            //console.log('getNovelDetail : ', res.data);
                            this.novelDetail = res.data
                            var g = new Date(this.novelDetail.novel_update)
                            var novel_update_time = g.getTime()
                            var d = new Date();
                            // Set it to one month ago
                            var amonthbefor = d.setMonth(d.getMonth() - 1);
                            if(amonthbefor > novel_update_time){
                                this.is_not_update_a_month = true
                            }
                            //document.title = this.novelDetail.novel_title + " | NovelRealm"
                            this.SET_LOADING_STATUS(false)
                            if (this.novelDetail.buy_book == false) {
                                this.isBuy = false
                            } else {
                                this.isBuy = true
                            }
                        })
                } catch (err) {
                    //console.log(err)
                }
            } else {
                const {
                    data
                } = await axios.get('https://api2.novelrealm.com/module/novel&submodule=detail&novel_id=' + this.$route.params.novel_id)
                this.novelDetail = data
                this.SET_LOADING_STATUS(false)
            }
            for (var n = 0; n < this.novelDetail.novel_count / 50; n++) {
                this.options.push({
                    value: n,
                    text: this.novelDetail.page_option[n].start + ' - ' + this.novelDetail.page_option[n].end 
                })
                if(this.initial == true && this.isLogin){
                    if(this.novelDetail.continue_chapter_id != null){
                        if(this.novelDetail.continue_chapter_id.chapter_ep >= this.novelDetail.page_option[n].start && this.novelDetail.continue_chapter_id.chapter_ep <= this.novelDetail.page_option[n].end){
                            this.continue_page = n
                        }
                    }
                    else{
                        this.novelDetail.continue_chapter_id = {
                            chapter_ep: -1,
                            chapter_id: -1
                        }
                    }
                }
            }
            
            setTimeout(() => {
                this.checkMode()
            }, 5);
        },
        async getChapter(route = false){
            if(route == true){
                this.selected = '0'
            }
            this.isLoadData = true
            const formData = new FormData();
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                const {
                    data
                } = await axios.post('https://api2.novelrealm.com/module/novel&submodule=chapter_list&novel_id=' + this.$route.params.novel_id + '&start=' + this.selected * 50 + '&limit=50', formData)
                this.chapter = data
                this.isLoadData = false
            } else {
                const {
                    data
                } = await axios.get('https://api2.novelrealm.com/module/novel&submodule=chapter_list&novel_id=' + this.$route.params.novel_id + '&start=' + this.selected * 50 + '&limit=50')
                this.chapter = data
                this.isLoadData = false
            }
            this.checkMode()
        },
        async checkShelf(novel_id) {
            const formData = new FormData();
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
            }
            formData.append('novel_id', novel_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/novel&submodule=cheif', formData)
                    .then((res) => {
                        //console.log('isInShelf : ', res)
                        if (res.data.description == 'not_in') {
                            this.isInShelf = false
                        } else if (res.data.description == 'in') {
                            this.isInShelf = true
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async addToShelf(novel_id) {
            this.spin = true
            const formData = new FormData();
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
            }
            formData.append('novel_id', novel_id);
            formData.append('action', 'toggle');
            try {
                await axios.post('https://api2.novelrealm.com/module/novel&submodule=cheif', formData)
                    .then((res) => {
                        //console.log('addToShelf : ', res)
                        this.spin = false
                        if (res.data.description == 'toggle') {
                            this.isInShelf = !this.isInShelf
                        } else {
                            alert('add failed !')
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async buyBook(novel_id) {
            const formData = new FormData();
            if (this.isLogin) {
                //console.log("login leaw")
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                formData.append('novel_id', novel_id);
            }
            try {
                await axios.post('https://api2.novelrealm.com/module/novel&submodule=buy_book', formData)
                    .then((res) => {
                        //console.log(res)
                        if (res.status == 200) {
                            this.showBuyModal = true
                            var book_amount = parseInt(res.data.book_amount_not_buy_after_discount)
                            var member_gold = parseInt(res.data.member_gold)
                            var goldEnough
                            //console.log("BA"+book_amount)
                            //console.log("MG"+member_gold)
                            if (book_amount <= member_gold) {
                                goldEnough = true
                            }
                            else{
                                goldEnough = false
                            }
                            this.detailForBookModal = {
                                novel_id: this.novelDetail.novel_id,
                                novel_title: this.novelDetail.novel_title,
                                novel_cover: this.novelDetail.novel_cover,
                                ishide: this.novelDetail.novel_is_hide,
                                book_amount: res.data.book_amount,
                                book_amount_not_buy_after_discount: book_amount,
                                goldEnough: goldEnough,
                                member_gold: res.data.member_gold,
                                buyed: res.data.status
                            }
                            
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async getPackChapter(route = false){
            if(route == true){
                this.selected = '0'
            }
            this.isLoadData = true
            const formData = new FormData();
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                const {
                    data
                } = await axios.post('https://api2.novelrealm.com/module/novel&submodule=chapter_pack&novel_id=' + this.$route.params.novel_id + '&start=' + this.selected * 60 + '&limit=60', formData)
                this.chapter = data
                this.isLoadData = false
            }
            else{
                const {
                    data
                } = await axios.get('https://api2.novelrealm.com/module/novel&submodule=chapter_pack&novel_id=' + this.$route.params.novel_id + '&start=' + this.selected * 60 + '&limit=60')
                this.chapter = data
                this.isLoadData = false
            }
            this.checkMode()
        },
        // Get comment count
        async getCommentCount() {
            const formData = new FormData();
            formData.append('start', 0);
            formData.append('limit', 10);
            formData.append('mode', true);
            if (this.isLogin) {
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
            }
            formData.append('novel_id', this.$route.params.novel_id);
            try {
                await axios.post('https://api2.novelrealm.com/?module=comment&submodule=view', formData)
                    .then((res) => {
                        //console.log(res.data);
                        this.commentCount = parseInt(res.data.count)
                    })
            } catch (err) {
                // console.log('FAILURE!!' + err)
            }
        },
        async routePage() {
            this.isPacktoggleload = true
            var n
            this.options = []
            for (n = 0; n < this.novelDetail.novel_count / 50; n++) {
                this.options.push({
                    value: n,
                    text: this.novelDetail.page_option[n].start + ' - ' + this.novelDetail.page_option[n].end 
                })
            }
            await this.getChapter(true)
            this.isPack = false
            this.isPacktoggleload = false
        },
        selectWorker(){
            console.log("Reloading...")
            if(this.isPack){
                this.getPackChapter()
            }
            else{
                this.getChapter()
            }
        },
        goToPayment() {
            this.showBuyModal = false
            window.open("/payment", '_blank')
        },
        async routePack(){
            this.isPacktoggleload = true
            var n
            this.options = []
            for (n = 0; n < this.novelDetail.novel_count / 60; n++) {
                this.options.push({
                    value: n,
                    text: this.novelDetail.page_pack_option[n].start + ' - ' + this.novelDetail.page_pack_option[n].end 
                })
            }
            
            await this.getPackChapter(true)
            this.isPack = true
            this.isPacktoggleload = false
        },
        changeFormatNumber(data) {
            var key = []
            Object.keys(data).forEach(element => {
                key.push(element)
            });

            var numeral = require('numeral');
            var value = []
            Object.values(data).forEach(element => {
                value.push(numeral(element).format('0,0'))
            });

            for (var n = 0; n < key.length; n++) {
                this.paymentInfo[key[n]] = value[n]
            }
            //console.log('this.paymentInfo : ', this.paymentInfo)
        },
        continueReading(continue_chapter_id) {
            router.push(window.location.pathname + "/chapter/" + continue_chapter_id);
        },
        gotoUser(){
            router.push("/user/" + this.novelDetail.novel_author.member_id);
        },
        openBuyRangemodal(){
            this.showBuyChapterRangeModal = true
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            try{
                document.getElementById('componentScope1').classList.remove('darkmode')
                document.getElementById('componentScope2').classList.remove('darkmode')
            }catch{
                //do no thing
            }
        },
        darkmode() {
            document.getElementById('componentScope1').classList.add('darkmode')
            document.getElementById('componentScope2').classList.add('darkmode')
        }
    }
}
</script>

<style scoped>
.scope_novel {
    margin: 3% 10% 3% 10%;
}

.componentScope {
    background-color: #ffffff;
    padding: 2.5% 1%;
}

.darkmode {
    background-color: #35363A;
}

.novelCover {
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}

.title {
    font-size: 25px;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.divider {
    margin: 10px 0;
}

.btn {
    border-radius: 7px;
    padding: 3px 10px;
    text-align: center;
    color: #ffffff;
}

.btn:hover {
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;
}

.continueRead {
    width: 100%;
    background-color: #F5D087;
}

@media (min-width: 768px) {
    .continueRead{
        width: auto;
    }
}

.continueRead:hover {
    background-color: #FAC762;
}

.addToShelf {
    background-color: #EE806B;
    width: 100%;
}

.w-full{
    width: 100%;
}

@media (min-width: 768px) {
    .addToShelf{
        width: auto;
    }
    .md-w-auto{
        width: auto;
    }
    .mobile-w-auto{
        width: auto !important;
    }
}

.addToShelf:hover {
    background-color: rgb(235, 116, 92);
}

.buyNovel {
    background-color: #042446;
}

.buyNovel:hover {
    background-color: #000a14;
}


.continueRead:hover,
.addToShelf:hover,
.buyNovel:hover,
/* .searchIcon:hover, */
.chapter:hover,
#textReadMore:hover {
    cursor: pointer;
    font-weight: bold;
}

.fontsize {
    font-size: 16px;
}

.chapterpack{
    font-size: 16px;
}

#textReadMore {
    margin: 0% 2% 0% 2%;
    font-size: 14px;
    color: #EE806B;
}

.desc {
    margin: 2% 2% 0% 2%;
    /* display: block; */
    height: 9em;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}

.descActive {
    height: fit-content;
    /* max-height: 500px; */
    transition: max-height 0.25s ease-in;
}

.headTable {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 0.5px solid rgba(143, 143, 143, 0.541);
}

/* .searchIcon {
    color: #ffffff;
    background-color: #F5D087;
    padding: 6px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
} */

/* .searchIcon:hover {
    background-color: #FAC762;
} */

.chaptertable {
    font-size: 15px;
}

.chapter {
    transform: translateZ(0px);
    transition-duration: .5s;
}

.chapter:hover {
    background-color: rgba(238, 129, 107, 0.212);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.062);
    border-radius: 6px;
    transform: scale(1.01);
}

.chapterLength {
    font-size: 12px;
    color: #ADADAD;
    margin-top: -3px;
}

.verticalCenter {
    margin-top: auto;
    margin-bottom: auto;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 7px;
    text-align: center;
    width: 100%;
}

.warn {
    font-size: 14px;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #042446;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.addCoin {
    color: #ffffff;
    font-weight: bold;
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    background-color: #EE806B;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.bougth {
    color: #ffffff;
    padding: 3px;
    border-radius: 4px;
    background-color: #EE806B;
}

.bi-check {
    background: #EE806B;
    color: white;
    border-radius: 3px;
    font-size: 18px;
}

.addCoin:hover {
    background-color: rgb(235, 116, 92);
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .scope_novel {
        margin: 2% 2% 2% 2%;
        padding: 2%;
    }

    .componentScope {
        padding: 8% 1%;
    }

    .novelCover {
        margin-bottom: 2%;
        border-radius: 3px;
        width: 130px;
    }

    .img {
        text-align: center;
        margin-bottom: 4%;
    }

    .title {
        font-size: 15px;
    }

    .chaptertable,
    .fontsize {
        font-size: 14px;
    }
}

@media screen and (min-width: 1441px) {
    .scope_novel {
        margin: 3% 20% 3% 20%;
    }
}
.author_label{
    margin-bottom:0px;
    margin-left:10px;
    vertical-align: middle;
}

.author_label:hover{
    cursor: pointer;
    color: rgba(238, 129, 107);
    -webkit-transition: color 0.5s;
    transition: color 0.5s;
}
.w-full{
    width: 100%;
}
</style>
